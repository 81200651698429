import React from "react";
import "./Login.css"
import {signup} from "../fetches/requests";

export default class RegisterBox extends React.Component {


    constructor(props) {
        super(props);


        this.state = {
            username: "",
            email: "",
            password: "",
            errors: [],
        };
    }


    showValidationErr(elm, msg) {
        this.setState((prevState) => ({
            errors: [
                ...prevState.errors, {
                    elm,
                    msg
                }
            ]
        }));
    }

    clearValidationErr(elm) {
        this.setState((prevState) => {
            let newArr = [];
            let err = null;
            for (err of prevState.errors) {
                if (elm !== err.elm) {
                    newArr.push(err);
                }
            }
            return {errors: newArr};
        });
    }

    onUsernameChange(e) {
        this.setState({username: e.target.value});
        this.clearValidationErr("username");
    }

    onEmailChange(e) {
        this.setState({email: e.target.value});
        this.clearValidationErr("email");
    }

    onPasswordChange(e) {
        this.setState({password: e.target.value});
        this.clearValidationErr("password");

    }

    submitRegister(e) {

        console.log(this.state);

        if (this.state.username === "") {
            this.showValidationErr("username", "Username Cannot be empty!");
        }
        if (this.state.email === "") {
            this.showValidationErr("email", "Email Cannot be empty!");
        }
        if (this.state.password === "") {
            this.showValidationErr("password", "Password Cannot be empty!");
        }


        signup( {username:this.state.username, email:this.state.email, password:this.state.password})
            .then(resp => {
                this.showValidationErr("register","registration was succesfull, you will be redirected to login");
                setTimeout(() =>{window.location.reload()},4000);
                }).catch(err => this.showValidationErr("register",err.response.data.message))
    }


    render() {
        let usernameErr = null,
            passwordErr = null,
            emailErr = null;
        let registerErr = null;
        let err = null;

        for (err of this.state.errors) {
            if (err.elm === "username") {
                usernameErr = err.msg;
            }
            if (err.elm === "password") {
                passwordErr = err.msg;
            }
            if (err.elm === "email") {
                emailErr = err.msg;
            }

            if(err.elm === "register"){
                registerErr = err.msg
            }
        }


        return (

            <div className="inner-container">
                <div className="header">
                    Register
                </div>

                <small className="danger-error">{registerErr
                    ? registerErr
                    : ""}</small>

                    <div className="box">

                    <div className="input-group">
                        <label htmlFor="username">Username</label>
                        <input
                            type="text"
                            name="username"
                            className="login-input"
                            placeholder="Username"
                            onChange={this
                                .onUsernameChange
                                .bind(this)}/>
                        <small className="danger-error">{usernameErr
                            ? usernameErr
                            : ""}</small>
                    </div>

                    <div className="input-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="text"
                            name="email"
                            className="login-input"
                            placeholder="Email"
                            onChange={this
                                .onEmailChange
                                .bind(this)}/>
                        <small className="danger-error">{emailErr
                            ? emailErr
                            : ""}</small>
                    </div>

                    <div className="input-group">
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            name="password"
                            className="login-input"
                            placeholder="Password"
                            onChange={this
                                .onPasswordChange
                                .bind(this)}/>
                        <small className="danger-error">{passwordErr
                            ? passwordErr
                            : ""}</small>


                    </div>

                    <button
                        type="button"
                        className="login-btn"
                        onClick={this
                            .submitRegister
                            .bind(this)}>Register
                    </button>

                </div>
            </div>

        )


    }


}