import React from 'react';
import { Slide } from 'react-slideshow-image';

import slide1 from './img/slide1.jpg'
import slide2 from './img/slide2.jpg'
import slide3 from './img/slide3.jpg'
import slide4 from './img/slide4.jpg'
import slide5 from './img/slide5.jpg'

import './Slideshow.css'

const  slideImages = [
    slide1,
    slide2,
    slide3,
    slide4,
    slide5
];

const properties = {
    duration: 5000,
    transitionDuration: 800,
    infinite: true,
    indicators: true,
    arrows: true
};


function slides(imgs) {
    return  imgs.map((img, index )=> {
        return(
            <div className="each-slide" key={index}>
                <div>
                    <img src={img} alt="img" />
                </div>
            </div>
        )
    });


}

function shuffle(originalArray) {
    var array = [].concat(originalArray);
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
}






const Slideshow = () => {


    let newarray = shuffle(slideImages)



    return (
        <div className="slide-container">
            <Slide {...properties}>
                {slides(newarray)}
            </Slide>
        </div>
    )
};



export default Slideshow;








