import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import * as serviceWorker from './serviceWorker';

import MenuBarAndSideBar from "./navigation/MenuBarAndSideBar"
import Router from "./Router/Router";



const app =  document.getElementById('root');


ReactDOM.render(

    <React.Fragment>


        <MenuBarAndSideBar/>

        {Router}

    </React.Fragment>



    , app);
































// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
