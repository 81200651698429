import axios from "axios"
const {apiLink} = require('../Constants');


axios.interceptors.request.use(function(config) {
    const token = gettoken()

    if ( token != null ) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
}, function(err) {
    return Promise.reject(err);
});


function gettoken(){
    return  localStorage.getItem("accestokenJwt");
}


export function getRoles(){
    return axios.get(apiLink + 'users/roles')
}


export function uploadfolder(data,options) {
    return axios.post(apiLink+'admin/uploadfolder',data,options)
}

export function deletePdf(id) {

    return axios.delete(apiLink +'admin/onefolder/'+id)

}

export function getAllPdfs() {

    return axios.get(apiLink +'folder/getallpdfs')

}

export function signin(data) {
    return axios.post(apiLink + 'users/signin', data)

}

export function signup(data) {
    
    return axios.post(apiLink + 'users/signup', data)

}


export function sendEmail(data) {

    return axios.post(apiLink + 'contact/submit', data)

}














