
import React from "react";
import LoginBox from "./LoginBox";
import RegisterBox from "./RegisterBox";
import "./Login.css"
import {metaLogin} from "../Metatags/metaData";

export default class Login extends React.Component{


    constructor(props){
        super(props);
        this.state = {
            isLoginOpen : true,
            isRegisterOpen : false
        }
    }


    showLoginBox() {
        this.setState({isLoginOpen: true, isRegisterOpen: false});
    }
    showRegisterBox() {
        this.setState({isRegisterOpen: true, isLoginOpen: false});
    }

    renderLoginBox(){
        return(<div className={(this.state.isLoginOpen
            ? "box-container"
            : "box-container-hide")}>
            <LoginBox/>
        </div>)

    }

    renderRegiserBox(){
        return(
            <div className={(this.state.isRegisterOpen
                ? "box-container"
                : "box-container-hide")}>
                <RegisterBox/>
            </div>
        )

    }





    render() {

        return(

            <>
                {metaLogin()}


            <div className="root-container">

                <div className="box-controller">
                    <div
                        className={"controller " + (this.state.isLoginOpen
                            ? "selected-controller"
                            : "")}
                        onClick={this
                            .showLoginBox
                            .bind(this)}>
                        Login
                    </div>
                    <div
                        className={"controller " + (this.state.isRegisterOpen
                            ? "selected-controller"
                            : "")}
                        onClick={this
                            .showRegisterBox
                            .bind(this)}>
                        Register
                    </div>
                </div>

                {this.state.isLoginOpen ? this.renderLoginBox():""}
                {this.state.isRegisterOpen ? this.renderRegiserBox(): ""}
            </div>
        </>
        )

    }


}