import React from 'react'
import MenuLogo from "../logo_vanlanduyt-wauters_groot.jpg"
import './Toolbar.css'
import DrawerToggleButton from "../SideDrawer/DrawerToggleButton";

const toolbar = props =>{

    let username = localStorage.getItem("username");

return (
    <>

    <div className="toolbar_logo" >
        <a href="/"> <img src={MenuLogo}  alt = {"Van Landuyt Vleeswaren"}/> </a>
    </div>

    <header className="toolbar">
        <nav className="toolbar_navigation">
            <div className="toolbar_toggle-button">
                <DrawerToggleButton click = {props.drawerClickHandler}/> </div>


            <div className="toolbar_navigation-items">
                <ul>
                    <li>
                        <a href="../folder">Folder</a>
                    </li>
                    <li>
                        <a href="../contact">Contact</a>
                    </li>
                    <li>
                        {username? <a href="../user">{username}</a> : <a href="../login">Login</a> }
                    </li>

                </ul>
            </div>
        </nav>
    </header>
        </>

)};

export default toolbar