import React from 'react';
import ReactLoading from 'react-loading';
import './Loading.css'

const LoadingAnimation = (props) => (

    <div className="loading-animation">
        <ReactLoading className={"loading-item"}  type={"spin"} color = {"#cccccc"} height={'15%'} width={'15%'}   />
    </div>
);

export default LoadingAnimation;
