import React from "react";

import './SideDrawer.css'
import MenuLogo from "../logo_vanlanduyt-wauters_groot.jpg";

const sideDrawer = props => {
    let username = localStorage.getItem("username");

    let drawerClasses = 'side-drawer';
    if (props.show) {
        drawerClasses = 'side-drawer open';
    }
    else {
        drawerClasses = 'side-drawer';
    }


    return (
        <nav className={drawerClasses}>

            <div className="sidebar-logo-container">
                <div className="sidebar-logo">
                <a href="/"> <img className="sidebar-logo" src={MenuLogo} alt = {"Van Landuyt Vleeswaren"}/> </a>
            </div>

            <div className="sidebar-strook">
            </div>
            </div>
            <ul>
                <li>
                    <a href="../folder">Folder</a>
                </li>
                <li>
                    <a href="../contact">Contact</a>
                </li>
                <li>
                    {username? <a href="../user">{username}</a> : <a href="../login">Login</a> }
                </li>


            </ul>
        </nav>
    );
}

export default sideDrawer;