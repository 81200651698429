import MetaTags from 'react-meta-tags';
import React from "react";


export function metaHomepage() {
    
    return(
        <MetaTags>
            <title>
                Van Landuyt vleeswaren home
            </title>
            <meta name={"description"} content={"Van Landuyt vleeswaren & Wauters, al generaties lang een familiebedrijf gespecialiseerd in charcuterie, vleeswaren, salades, kazen en zo veel meer. De nummer één stop voor slagers, traiteurs  broodjeszaken, evenementen en voedingszaken..."}/>
            <meta property="og:title" content="Van Landuyt Vleeswaren" />
            <meta property="og:image" content="" />
        </MetaTags>
    )
}


export function metaFolder() {

    return(
        <MetaTags>
            <title>
                Van Landuyt vleeswaren folder
            </title>
            <meta name={"description"} content={"Bekijk onze maandelijkse folder voor promoties"}/>
            <meta property="og:title" content="Van Landuyt vleeswaren folder pagina" />
            <meta property="og:image" content="" />
        </MetaTags>
    )
}

export function metaHistory() {
    
    return(<MetaTags>
        <title>
            Van Landuyt vleeswaren history
        </title>
        <meta name={"description"} content={"Met het al generaties lange bestaan van de familieonderneming, komt een heuse geschiedenis naar boven"}/>
        <meta property="og:title" content="Van Landuyt vleeswaren history pagina" />
        <meta property="og:image" content="" />
    </MetaTags>)

}

export function metaLogin() {

    return(
        <MetaTags>
            <title>
                Van Landuyt vleeswaren login
            </title>
            <meta name={"description"} content={"Login voor talrijke extra functionaliteiten"}/>
            <meta property="og:title" content="Van Landuyt vleeswaren login pagina" />
            <meta property="og:image" content="" />
        </MetaTags>
    )
    
    
}

export function metaContact() {
    
    return(

        <MetaTags>
            <title>
                Van Landuyt vleeswaren contact
            </title>
            <meta name={"description"} content={"Contact informatie: mail, telefoon, fax, adres "}/>
            <meta property="og:title" content="Van Landuyt vleeswaren contact pagina" />
            <meta property="og:image" content="" />
        </MetaTags>
    )
    

}