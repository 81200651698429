import React from "react";




export default class AdminPage extends React.Component{

    constructor(props) {
        super(props);
        this.state = {


        }
    }

    componentDidMount(){
    }
    render() {

        return(

            <>

                <div>
                    <h1> Welkom op de admin page</h1>

                    <div> <a href="../admin/uploadfolder">om een folder toe te voegen</a></div>
                    <div><a href="../admin/folderAdminOverview"> om een folder te verwijderen</a></div>


                </div>
                </>
        )

    }


}