import React from "react";
import "./Login.css"
import { withRouter } from 'react-router-dom';
import {signin} from "../fetches/requests";


export default withRouter( class LoginBox extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            email: "",
            password: "",
            errors: [],


        }


    }

    submitLogin(e) {

        if (this.state.email === "") {
            this.showValidationErr("email", "Email Cannot be empty!");
        }

        if (this.state.password === "") {
            this.showValidationErr("password", "Password Cannot be empty!");
        }

        signin({email: this.state.email, password: this.state.password})
            .then(resp => {localStorage.setItem("accestokenJwt",resp.data.accestokenJwt); localStorage.setItem("username",resp.data.username); localStorage.setItem("email", resp.data.email);
            this.props.history.push('./user')
        }).catch(err => this.showValidationErr("login","Gebruikersnaam of passwoord is verkeerd"))


    }

    showValidationErr(elm, msg) {
        this.setState((prevState) => ({
            errors: [
                ...prevState.errors, {
                    elm,
                    msg
                }
            ]
        }));
    }

    clearValidationErr(elm) {
        this.setState((prevState) => {
            let newArr = [];
            let err = null;
            for (err of prevState.errors) {
                if (elm !== err.elm) {
                    newArr.push(err);
                }
            }
            return {errors: newArr};
        });
    }

    onEmailChange(e) {
        this.setState({email: e.target.value});
        this.clearValidationErr("email");
    }

    onPasswordChange(e) {
        this.setState({password: e.target.value});
        this.clearValidationErr("password");

    }


    render() {
        let emailErr = null,
            passwordErr = null;
        let loginErr = null;
        let err = null;
        for (err of this.state.errors) {

            if(err.elm === "login"){
                loginErr = err.msg
            }

            if (err.elm === "email") {
                emailErr = err.msg;
            }
            if (err.elm === "password") {
                passwordErr = err.msg;
            }

        }


        return (
            <div className="inner-container">
                <div className="header">
                    Login


                </div>
                <small className="danger-error">{loginErr
                    ? loginErr
                    : ""}</small>


                <div className="box">

                    <div className="input-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="text"
                            name="email"
                            className="login-input"
                            placeholder="email"
                            onChange={this.onEmailChange.bind(this)}/>

                        <small className="danger-error">{emailErr
                            ? emailErr
                            : ""}</small>


                    </div>

                    <div className="input-group">
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            name="password"
                            className="login-input"
                            placeholder="Password"
                            onChange={this.onPasswordChange.bind(this)}
                        />

                        <small className="danger-error">{passwordErr
                            ? passwordErr
                            : ""}</small>


                    </div>

                    <button
                        type="button"
                        className="login-btn"
                        onClick={this
                            .submitLogin
                            .bind(this)}>Login
                    </button>

                </div>
            </div>
        )
    }


}
)