import React from "react";

import {ProgressBar} from 'react-bootstrap';

import "./admin.css"

import DatePicker from "react-datepicker"
import 'react-datepicker/dist/react-datepicker.css'

import {Button} from "react-bootstrap";
import {uploadfolder} from "../fetches/requests";


export default class UploadFolder extends React.Component{



    state = {
        uploadPercentage: 0,
        startDate: new Date(),
        endDate : new Date(),
        startweek : null,
        endweek : null,
        file : null,
        manualname: null
    }

    manualeNameHandel(e) {
        this.setState({manualname: e.target.value});
    }


    fileselected = ({target: { files }}) => {
        this.setState({file:files[0]})

    }

    startweekselected(e) {
        this.setState({startweek: e.target.value});

    }


    endweekselected(e) {
        this.setState({endweek: e.target.value});

    }





    uploadFile = () =>{


        let data = new FormData();

        let name = 'GastroCom_' + this.state.startweek + '-' + this.state.endweek + '_' + this.state.startDate.getFullYear() % 100;

        // can also be done in 1 liner but yea
        if (this.state.manualname){
            name = this.state.manualname;
        }

        data.append( 'file', this.state.file, name )
        let restdata = {
            validFrom: this.state.startDate,
            validTill : this.state.endDate


        }

        data.append('Dates',JSON.stringify(restdata))

        const options = {
            'Content-Type': 'multipart/form-data',


            onUploadProgress: (progressEvent) => {
                const {loaded, total} = progressEvent;
                let percent = Math.floor( (loaded * 100) / total )

                if( percent <= 100 ){
                    this.setState({ uploadPercentage: percent })
                }

                if(loaded === total){
                   // window.window.location.reload()
                }
            }
        }

        uploadfolder(data,options).then(res => {
        }).catch(err => console.log(err))
    }

    render() {
        const {uploadPercentage} = this.state;

        let displaybool = this.state.file && this.state.endDate && this.state.endweek && this.state.startDate && this.state.startweek
        let displaynaam = 'GastroCom_' + this.state.startweek + '-' + this.state.endweek + '_' + this.state.startDate.getFullYear() % 100

        if (this.state.manualname){
            displaynaam = this.state.manualname;
        }


        return (
            <div className="root-container">

                <div className="">
                    <div className="">


                            <input type="file" className="" onChange={this.fileselected} />
                            { uploadPercentage > 0 ? <ProgressBar now={uploadPercentage} active label={`${uploadPercentage}%`} /> : "" }
                            
                    </div>

                    <div>
                        <label >startdatum : <DatePicker selected={this.state.startDate}  onChange={date => this.setState({startDate : date})} /></label>
                    </div>
                    <div>
                        <label> einddatum: <DatePicker selected={this.state.endDate} onChange={date => this.setState({endDate:date})} /> </label>

                    </div>

                   <div><label > weekbegin <input type="number" onChange={e => this.startweekselected(e)}/></label></div>
                    <div><label > weekeinde <input type="number" onChange={e => this.endweekselected(e)} /></label></div>


                    <div><label> folder naam is of edit hier: <input type="text" defaultValue={displaynaam} value={displaynaam}  onChange={e => this.manualeNameHandel(e)} /></label></div>

                </div>


                {displaybool ? <Button variant="light" onClick={this.uploadFile} > upload file</Button>
                    : <Button variant="light" disabled> upload file</Button>}


            </div>
        );
    }
}
















