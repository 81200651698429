
import React from "react";
import Slideshow from "./SlideShow/Slideshow";
import {metaHomepage} from "../Metatags/metaData";


export default class Home extends React.Component{

    render() {

        return(
            <div>

                {metaHomepage()}

                <Slideshow/>

            </div>

        )

    }


}



