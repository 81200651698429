import './History.css'
import React from "react";
import {metaHistory} from "../Metatags/metaData";


export default class History extends React.Component{

    render() {

        return(

            <>
                {metaHistory()}



            <div className="history-container">
                <h1>history pagina</h1>
                <h2>Still writing history</h2>

            </div>

          </>

        )

    }


}