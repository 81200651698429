import './Folder.css'
import React from "react";
import LoadingAnimation from "../Animations/Loading";
import {getAllPdfs} from "../fetches/requests";
import {metaFolder} from "../Metatags/metaData";


const {apiLink} = require('../Constants')



export default class Folder extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            pdfs: null
        }
    }

    componentDidMount(){
        getAllPdfs().then(pdfs =>  this.setState({pdfs:pdfs.data,isLoading:false})).catch(err => console.log(err))
    }


    dateToString(datum){
        let opties = {year:'numeric',
            month:'2-digit',
            day:'2-digit'}

        return(new Date(datum).toLocaleString("nl",opties))
    }

    nameToLink(naam){

        return <a href={apiLink + 'folder/onefolder/' + naam} target="_blank" rel="noopener noreferrer" > {naam} </a>
    }
    
    renderPDFLinks(){
        const columns =  [
            {
                Header: 'Folder',
                accessor: 'name',
            },
            {
                Header: 'Van',
                accessor: 'validFrom',
            },
            {
                Header: 'Tot',
                accessor: 'validTill',
            }
        ];
        return (
            <table>

                <thead>
                <tr>
                    {columns.map(i => {
                        return(
                            <th key={i.Header}>{i.Header} </th>
                        )
                    })}
                </tr>
                </thead>

                <tbody>
                {this.state.pdfs.map(pdf => {
                    return(
                        <tr key={pdf.name}>
                            <td>{this.nameToLink(pdf.name)} </td>
                            <td>{this.dateToString(pdf.validFrom)} </td>
                            <td>{this.dateToString(pdf.validTill)} </td>
                        </tr>
                )
                })}

                </tbody>

            </table>
                )
    }

    render() {

        if(this.state.isLoading){
            return (
                <>
                <div className="folder-container">

                <h1>Beschikbare folders:</h1>

                </div>
                <LoadingAnimation/>
                </>
            )
        }

        return(
            <>
                {metaFolder()}
            <div className="folder-container">
                <h1>Beschikbare folders:</h1>

                {this.renderPDFLinks()}
            </div>
          </>
        )
    }


}