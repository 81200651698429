import React from "react";
import {checkAdmin} from "../Authentication/Authentication";

export default class UserPage extends React.Component{

    constructor(props) {
        super(props);
        this.state = {


        }
    }

    componentDidMount(){
        // moet lukken want is in auth route

    }






    render() {



        if(!checkAdmin()){
            return (
                <>
                    <h1>Welkom gebruiker</h1>

                    </>
            )

        }


        else {

            return(

                <>

                    <div>
                        <h1> Welkom admin </h1>
                        <a href="../admin">admin pagina</a>
                    </div>
                </>
            )
        }
        
    }


}