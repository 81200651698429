import React from 'react';
import {
    Route,
    Redirect,
} from 'react-router-dom'
import decode from 'jwt-decode';


const checkAuth = () => {
    const token = localStorage.getItem('accestokenJwt');

    if (!token) {
        return false;
    }

    try {
        const { exp } = decode(token);

        if (exp < new Date().getTime() / 1000) {
            return false;
        }

    } catch (e) {
        return false;
    }

    return true;
}

export const checkAdmin = () =>{
    const token = localStorage.getItem('accestokenJwt');

    if (!token) {
        return false;
    }

    try {
        const { exp,roles } = decode(token);


        if (exp < new Date().getTime() / 1000) {
            return false;
        }

        if(!roles.includes('admin')){
            return false;
        }


    } catch (e) {
        return false;
    }
    return true;
}











export  const  AdminRoute =  ({ component: Component, ...rest }) => {

    return (
        <Route {...rest} render={props => (
            checkAdmin()? (
                <Component {...props} />
            ) : (
                <Redirect to={{pathname: '/login'}}/>
            )
        )}/>
    )
}




export const AuthRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        checkAuth() ? (
            <Component {...props} />
        ) : (
            <Redirect to={{ pathname: '/login' }} />
        )
    )} />
)