import './PageNotFound.css'
import React from "react";


export default class PageNotFound extends React.Component{

    render() {

        return(

            <div className="not-found-container">
                <h1>404</h1>
                <h2>De opgegeven pagina url is verplaatst of bestaat niet</h2>

            </div>

        )

    }


}