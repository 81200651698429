import React from 'react';
import {Redirect, Route, Switch} from 'react-router';
import {BrowserRouter as Router} from "react-router-dom";
import Home from "../Home/Home";
import Login from "../Login/Login";
import Contact from "../Contact/Contact";
import History from "../History/History";
import Folder from "../Folder/Folder";
import PageNotFound from "../404/PageNotFound";
import AdminPage from "../Admin/AdminPage";

import {AdminRoute, AuthRoute} from "../Authentication/Authentication";
import UserPage from "../User/UserPage";
import UploadFolder from "../Admin/UploadFolder";
import FolderAdminOverview from '../Folder/FolderAdminOverview';


export default (

    <Router>
        <Switch>
            <Route exact path='/' name = {"Home"} component={Home} />
            <Route exact path= '/login' name = {"Login"} component={Login}/>
            <Route exact path= '/contact' name = {"Contact"} component={Contact}/>
            <Route exact path= '/history' name = {"History"} component={History}/>
            <Route exact path= '/folder' name = {"Folder"} component={Folder}/>

            <AdminRoute exact path = '/admin' component={AdminPage} />
            <AdminRoute exact path = '/admin/uploadfolder' component={UploadFolder}/>
            <AdminRoute exact path = "/admin/folderAdminOverview" component={FolderAdminOverview}/>

            <AuthRoute exact path = '/user' component={UserPage} />


            <Route path="/404" component={PageNotFound} />
            <Redirect to="/404" />

        </Switch>

    </Router>

);
