
module.exports = {

    apiLink : 'https://www.api.vanlanduytvleeswaren.be/api/'
    //apiLink: 'http://localhost:5000/api/'


};



