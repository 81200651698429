import React from "react";
import './Contact.css'
import {metaContact} from "../Metatags/metaData";
import ReCAPTCHA from "react-google-recaptcha";
import {sendEmail} from "../fetches/requests";
import {EnvelopeFill, PersonFill, ChatLeftFill, TelephoneFill, FileEarmarkBreakFill} from 'react-bootstrap-icons';

import {Button} from "react-bootstrap";

export default class Contact extends React.Component {


    constructor(props) {
        super(props);


        this.state = {
            opencontact: false,
            emailName: "",
            emailAdress: "",
            emailText: "",
            captcha: null,
            sendbutton: true

        };
    }

    opencontactform(e) {
        this.setState({opencontact: true})

    }


    onEmailNameChange(e) {
        this.setState({emailName: e.target.value})
    }

    onEmailAddressChange(e) {
        this.setState({emailAdress: e.target.value})
    }

    onEmailTextChange(e) {
        this.setState({emailText: e.target.value})

    }

    SendForm(e) {

        this.setState({sendbutton: false});

        let data = {
            name: this.state.emailName,
            email: this.state.emailAdress,
            text: this.state.emailText,
            captcha: this.state.captcha

        };

        setTimeout(() => {
            this.setState({sendbutton: true})
        }, 5000);

        sendEmail(data).then(() => {
            if (window.confirm("uw vraag is goed ontvangen")) window.location.reload()
        }).catch(err => window.confirm(err.response.data.message))
    }

    onChangeCaptcha(value) {
        this.setState({captcha: value})
    }


    render() {

        return (

            <>
                {metaContact()}


                <div className="contact-container">


                    <h1 className="contact-middle">Contact</h1>
                    <div className="contact-info">


                        <h4><TelephoneFill/> Tel:<a href="tel:+3254342011"> +3254342011 </a></h4>
                        <h4><FileEarmarkBreakFill/> Fax: +3254320038</h4>
                        <h4><EnvelopeFill/> Mail:<a
                            href="mailto:verkoop@vanlanduytvleeswaren.be"> verkoop@vanlanduytvleeswaren.be </a>
                        </h4>

                    </div>


                    <h1 className="contact-middle">Adres</h1>
                    <div className="contact-info">

                        <h4>Neep 48</h4>
                        <h4>9402 Meerbeke </h4>
                        <h4>België </h4>


                    </div>


                    <div className="google-map">
                        <iframe title={"google maps"}
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d67825.52938996193!2d4.015116604371072!3d50.820395489864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf9cf5a4d24b7276e!2sVan%20Landuyt%20vleeswaren!5e0!3m2!1snl!2sbe!4v1582735130614!5m2!1snl!2sbe"
                                width="600" height="450" frameBorder="0" allowFullScreen=""/>
                    </div>


                    {this.state.opencontact ? <div/> : <Button
                        variant="danger"
                        name="Neem contact op"
                        className="sendbutton"
                        onClick={this
                            .opencontactform
                            .bind(this)}><EnvelopeFill/> Neem contact op
                    </Button>}

                    {this.state.opencontact ?


                        <div className="emailformcontainer">
                            <h1 className="contact-middle">Stel een vraag</h1>

                            <label><i className="" aria-hidden="true"/> <PersonFill/> Naam</label>

                            <div>
                                <input type="text" name="name" className=""
                                       onChange={this.onEmailNameChange.bind(this)}
                                       placeholder="Enter uw naam"/>
                            </div>

                            <label><i className=""
                                      aria-hidden="true"/> <EnvelopeFill/> Email</label>

                            <div>
                                <input type="email" name="email" className=""
                                       onChange={this.onEmailAddressChange.bind(this)}
                                       placeholder="Enter uw Email adres"/>

                            </div>

                            <label><i className=""
                                      aria-hidden="true"/> <ChatLeftFill/> Vraag</label>

                            <div className="vraagArena">
                                <textarea rows="3" name="message" className="vraagArena"
                                          onChange={this.onEmailTextChange.bind(this)}
                                          placeholder="Enter uw vraag"/>


                            </div>

                            <ReCAPTCHA
                                sitekey="6LfYhEEaAAAAAKFvph2b7Pk1xsoN-riDr4_gzA_3"
                                onChange={this.onChangeCaptcha.bind(this)}
                            />


                            <Button
                                variant="danger"
                                name="sendmail"
                                className="sendbutton"
                                disabled={!this.state.sendbutton || this.state.emailName === "" || this.state.emailAdress === "" || this.state.emailText.length < 10 || this.state.captcha === null}
                                onClick={this
                                    .SendForm
                                    .bind(this)}><EnvelopeFill/> Verstuur vraag
                            </Button>

                            <div>

                            </div>


                        </div>

                        : <div/>}

                </div>


                <div className="eindediv"/>

            </>

        )

    }

}